import React, { useState, useEffect } from "react";
import BlogList from "../components/BlogList";
import LoadingIndicator from "../components/LoadingIndicator";

const ArchivedPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch("/.netlify/functions/fetch-archived-posts")
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <div>
      <BlogList posts={posts} source='archived' />
    </div>
  );
};

export default ArchivedPosts;
