import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";

import i18n from "./services/i18n";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import KidsEducationPage from "./pages/KidsEducationPage";
import AdultEducationPage from "./pages/AdultEducationPage";

import DiasporaScholarshipPage from "./pages/DiasporaScholarshipPage";
import StipendiumHungaricumPage from "./pages/StipendiumHungaricumPage";
import ArchivedPosts from "./pages/ArchivedPosts";
import FacebookPhotoBlog from "./components/FacebookPhotoBlog";
import CulturePostsPage from "./pages/CulturePostsPage";
import BlogPost from "./components/BlogPost.js";
import SummerCampPage from "./pages/SummerCampPage.js";

const ALLOWED_LANGUAGES = ["en", "he", "hu"];

const WithLanguageRoutes = () => {
  const { language } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ALLOWED_LANGUAGES.includes(language)) {
      navigate(`/en`);
    } else {
      i18n.changeLanguage(language);
    }
  }, [language, navigate]);
  return (
    <>
      <Header />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path='about-us' element={<AboutUsPage />} />
        <Route path='contact' element={<HomePage />} />
        <Route path='edu' element={<HomePage />} />
        <Route path='edu/rakoczi-summer-camp' element={<SummerCampPage />} />
        <Route path='edu/kicsiknek' element={<KidsEducationPage />} />
        <Route path='edu/nagyoknak' element={<AdultEducationPage />} />
        <Route
          path='edu/stipendium-hungaricum-scholarship-hungary'
          element={<StipendiumHungaricumPage />}
        />
        <Route
          path='edu/diaspora-hungary-scholarship'
          element={<DiasporaScholarshipPage />}
        />

        <Route path='culture' element={<ArchivedPosts />} />
        <Route path='culture/past-projects' element={<ArchivedPosts />} />
        <Route path='culture/current-events' element={<CulturePostsPage />} />
        <Route path='galleries' element={<FacebookPhotoBlog />} />
        <Route path='/posts/:source/:slug' element={<BlogPost />} />
        <Route path='*' element={"404 Page not found"} />
      </Routes>
      <Footer />
    </>
  );
};

export default WithLanguageRoutes;
