import React from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FcAddressBook,
  FcCellPhone,
  FcHome,
  FcAbout,
  FcGallery,
} from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import ephraim_logo from "../files/ephraim_logo.jpg";
import "../styles/Footer.css";

const Footer = () => {
  const { t } = useTranslation();
  const { language } = useParams();

  return (
    <Navbar bg='dark' variant='dark' className='mt-5 footer'>
      <Container>
        <Row className='w-100'>
          <Col
            md={4}
            className='text-light d-flex flex-column align-items-center justify-content-center footer-logo-col'
          >
            <img
              src={ephraim_logo}
              alt='efraim-kishon-magyar-diaszpora-szervezet-izraelben-hetvegi-iskola-weekend-school-hungarian-diaspora-in-israel'
              className='footer-logo mb-2'
            />
            <a
              href='mailto:kishon.educenter@gmail.com'
              className='desktop-hide no-underline-white-text'
            >
              <FcAddressBook size='1.3em' className='icon' />{" "}
              kishon.educenter@gmail.com
            </a>
            <a
              href='tel:+972542252112'
              className='desktop-hide no-underline-white-text'
            >
              <FcCellPhone size='1.3em' className='icon' /> +972 54 225 2112
            </a>
            <a
              href='https://www.facebook.com/kh.educenter/'
              target='_blank'
              rel='noopener noreferrer'
              className='social-link desktop-hide'
            >
              <FaFacebook size='1.5em' /> {t("follow_facebook")}
            </a>
          </Col>

          <Col md={4} className='text-light footer-nav'>
            <Nav className='flex-column align-items-center justify-content-center'>
              <Nav.Link as={Link} to={`/${language}/`} className='footer-link'>
                <FcHome size='1.3em' /> {t("home_page")}
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={`/${language}/about-us`}
                className='footer-link'
              >
                <FcAbout size='1.3em' /> {t("about_us")}
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={`/${language}/galleries`}
                className='footer-link'
              >
                <FcGallery size='1.3em' /> {t("gallery")}
              </Nav.Link>
            </Nav>
          </Col>

          <Col md={4} className='text-light footer-contact-info'>
            <a
              href='mailto:kishon.educenter@gmail.com'
              className='footer-contact-link'
            >
              <FcAddressBook size='1.3em' className='icon' />{" "}
              kishon.educenter@gmail.com
            </a>
            <a href='tel:+972542252112' className='footer-contact-link'>
              <FcCellPhone size='1.3em' className='icon' /> +972 54 225 2112
            </a>
            <a
              href='https://www.facebook.com/kh.educenter/'
              target='_blank'
              rel='noopener noreferrer'
              className='social-link'
            >
              <FaFacebook size='1.5em' /> {t("follow_facebook")}
            </a>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
