import React from "react";

import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FcEmptyTrash } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
const BlogList = ({ posts, source }) => {
  const { t } = useTranslation();
  const { language } = useParams();
  if (posts.length === 0) {
    return (
      <Container className='text-center my-5'>
        <FcEmptyTrash size='5em' />
        <h3>{t("no_blog_posts")}</h3>
        <p>{t("check_back_later")}</p>
      </Container>
    );
  }
  return (
    <Container>
      <Row className='g-4'>
        {posts.map((post) => (
          <Col key={post._id} sm={12} md={6} lg={4}>
            <Card className='h-100'>
              <div className='aspect-ratio-box'>
                <Card.Img variant='top' src={post.coverImageUrl} />
              </div>
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>

                <Card.Text>{post.excerpt || "No description..."}</Card.Text>
                <LinkContainer
                  to={`/${language}/posts/${source}/${post.slug.current}`}
                >
                  <Button variant='primary'>{t("read_more")}</Button>
                </LinkContainer>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BlogList;
