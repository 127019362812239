import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import {
  RightAlignedDropdown,
  StyledReactCountryFlag,
} from "../styles/LanguageSelectorStyles";

const LanguageSelector = ({ isNavExpanded }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [selectedFlag, setSelectedFlag] = useState("GB");

  const changeLanguage = (language, flag) => {
    i18n.changeLanguage(language);
    setSelectedFlag(flag);
    const currentPath = window.location.pathname;

    const newPathParts = currentPath.split("/");
    newPathParts[1] = language;
    const newPath = newPathParts.join("/");

    navigate(newPath);
  };

  return (
    <RightAlignedDropdown>
      <Dropdown.Toggle
        as={StyledReactCountryFlag}
        countryCode={selectedFlag}
        svg
        id='dropdown-basic'
      />
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage("en", "GB")}>
          <ReactCountryFlag countryCode='GB' svg /> English
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage("he", "IL")}>
          <ReactCountryFlag countryCode='IL' svg /> עברית
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage("hu", "HU")}>
          <ReactCountryFlag countryCode='HU' svg /> Magyar
        </Dropdown.Item>
      </Dropdown.Menu>
    </RightAlignedDropdown>
  );
};

export default LanguageSelector;
