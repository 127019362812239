import { useState, useCallback } from "react";
const useFacebookPhotos = (pageId, token) => {
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nextPageCursor, setNextPageCursor] = useState(null);

  const fetchPhotos = useCallback(
    async (afterCursor = "") => {
      setIsLoading(true);
      let endpoint = `/.netlify/functions/fetch-facebook-photos?pageId=${pageId}`;
      if (afterCursor) {
        endpoint += `&after=${afterCursor}`;
      }

      try {
        const response = await fetch(endpoint);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        // Flatten the data structure to extract image data
        const newPhotos = data.data.flatMap((item) =>
          item.attachments
            ? item.attachments.data.flatMap((attachment) => {
                if (attachment.media && attachment.media.image) {
                  const images = attachment.subattachments
                    ? attachment.subattachments.data
                        .filter((sub) => sub.media && sub.media.image)
                        .map((sub) => ({
                          src: sub.media.image.src,
                          description: sub.description || "",
                          title: sub.title || "",
                          message: item.message || "",
                          source: sub.media.source || "",
                        }))
                    : [
                        {
                          src: attachment.media.image.src,
                          description: attachment.description || "",
                          title: attachment.title || "",
                          message: item.message || "",
                          source: attachment.media.source || "",
                        },
                      ];
                  return images;
                }
                return [];
              })
            : []
        );

        // Update the photo state, ensuring there are no duplicates
        setPhotos((prevPhotos) => {
          const allSrcs = new Set(prevPhotos.map((photo) => photo.src));
          const uniqueNewPhotos = newPhotos.filter(
            (newPhoto) => !allSrcs.has(newPhoto.src)
          );
          return [...prevPhotos, ...uniqueNewPhotos];
        });

        // Set the next page cursor for pagination
        setNextPageCursor(data.paging?.cursors?.after || null);
      } catch (error) {
        console.error("Error fetching photos:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [pageId]
  );

  return {
    photos,
    isLoading,
    error,
    nextPageCursor,
    fetchPhotos,
  };
};

export default useFacebookPhotos;
