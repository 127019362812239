import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { PortableText } from "@portabletext/react";
import BlogImages from "./BlogImages";
import YoutubeEmbed from "./YoutubeEmbed";
const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug, source } = useParams();

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const response = await fetch(
          `/.netlify/functions/fetch-single-post?slug=${slug}&source=${source}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postData = await response.json();
        setPost(postData);
      } catch (error) {
        console.error("Error fetching post:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPostData();
  }, [slug, source]);
  const linkSerializer = (props) => {
    const { value } = props;
    if (value.href) {
      if (
        value.href.startsWith("https://www.youtube.com") ||
        value.href.startsWith("https://youtu.be")
      ) {
        return <YoutubeEmbed url={value.href} />;
      } else {
        return (
          <a href={value.href} target='_blank' rel='noopener noreferrer'>
            {props.children}
          </a>
        );
      }
    } else {
      console.warn("Warning: Missing 'href' attribute in link mark", props);
      return <span>{props.children}</span>;
    }
  };

  if (loading) {
    return (
      <Container className='text-center'>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (!post) {
    return <Container>No post found!</Container>;
  }

  return (
    <Container className='mt-4'>
      <Row className='justify-content-md-center'>
        <Col md={8}>
          <Card>
            {post.coverImageUrl && (
              <Card.Img variant='top' src={post.coverImageUrl} />
            )}
            <Card.Body>
              <Card.Title as='h1'>{post.title}</Card.Title>
              <PortableText
                value={post.content}
                components={{
                  types: {
                    image: (props) => <BlogImages {...props} />,
                  },
                  marks: {
                    link: linkSerializer,
                  },
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BlogPost;
