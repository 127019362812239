import React from "react";
const BlogImages = ({ value }) => {
  return (
    <img
      src={value.url}
      alt={value.alt || " "}
      style={{ width: "100%", height: "auto" }}
    />
  );
};
export default BlogImages;
