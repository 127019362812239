import kindergarten from "../files/kindergarten3.jpg";
import school from "../files/school4.jpg";
import adult2 from "../files/zoom-session.jpg";
import placeholder from "../files/150.png";
import ringato from "../files/nursery.jpg";

import { FaUsers, FaHistory, FaRegHandshake } from "react-icons/fa";
import {
  FcReading,
  FcGlobe,
  FcMoneyTransfer,
  FcOk,
  FcOpenedFolder,
} from "react-icons/fc";

export const getImageById = (id) => {
  if (id) {
    return `http://localhost:5000/images/${id}`;
  }
};
export const translateMessage = (message, t) => {
  return t(message).replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
};
export const openDonationPopup = (e) => {
  e.preventDefault();
  const url = "https://www.paypal.com/donate/?hosted_button_id=G8XNJ5JLJXTEL";
  const popupWidth = 600;
  const popupHeight = 700;

  const windowWidth = window.screen.width;
  const windowHeight = window.screen.height;

  const left = windowWidth / 2 - popupWidth / 2;
  const top = windowHeight / 2 - popupHeight / 2;

  const windowFeatures = `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`;

  window.open(url, "DonatePopup", windowFeatures);
};
export const boldUppercase = (text) => {
  return text
    .split(" ")
    .map((word, index) => {
      if (/^[A-ZÁÉÍÓÖŐÚÜŰ]{2,}$/.test(word)) {
        return <strong key={index}>{word}</strong>;
      } else {
        return word;
      }
    })
    .reduce((acc, elem) => {
      return acc === null ? [elem] : [...acc, " ", elem];
    }, null);
};

export const stipendiumData = [
  {
    icon: <FcReading size='3em' />,
    title: "scholarship_study_opportunities",
    description: "scholarship_study_desc",
  },
  {
    icon: <FcGlobe size='3em' />,
    title: "scholarship_global_network",
    description: "scholarship_network_desc",
  },
  {
    icon: <FcMoneyTransfer size='3em' />,
    title: "scholarship_financial_support",
    description: "scholarship_financial_desc",
  },
];
export const diasporaData = [
  {
    icon: <FcOk size='3em' />,
    title: "scholarship_study2_opportunities",
    description: "scholarship_study2_desc",
    color: "#0476D0",
  },
  {
    icon: <FcOpenedFolder size='3em' />,
    title: "scholarship_global2_network",
    description: "scholarship_financial2_desc",
    color: "FFFFFF",
  },
];
export const valuesData = [
  {
    icon: <FaUsers />,
    title: "teachers",
    description: "teachers_desc",
  },
  {
    icon: <FaHistory />,
    title: "history",
    description: "about_us_desc2",
  },
  {
    icon: <FaRegHandshake />,
    title: "partnerships",
    description: "about_us_desc3",
  },
];
export const teamMembers = (t) => [
  {
    name_first: t("Judit"),
    name_second: t("West"),
    role: "CEO",
    imgSrc: placeholder,
  },
  {
    name_first: t("teacher1_first"),
    name_second: t("teacher1_second"),
    role: t("teacher"),
    imgSrc: placeholder,
  },
  {
    name_first: t("teacher2_first"),
    name_second: t("teacher2_second"),
    role: t("teacher"),
    imgSrc: placeholder,
  },
  {
    name_first: t("teacher3_first"),
    name_second: t("teacher3_second"),
    role: t("teacher"),
    imgSrc: placeholder,
  },
  {
    name_first: t("teacher4_first"),
    name_second: t("teacher4_second"),
    role: t("teacher_jerusalem"),
    imgSrc: placeholder,
  },
  {
    name_first: t("teacher5_first"),
    name_second: t("teacher5_second"),
    role: t("teacher_jerusalem"),
    imgSrc: placeholder,
  },
];
export const textStyle = {
  border: "1px solid #ccc",
  padding: "10px",
  margin: "10px 0",
  borderRadius: "5px",
  boxShadow: "2px 2px 10px rgba(0,0,0,0.1)",
  backgroundColor: "#f9f9f9",
};
export const programData = [
  {
    img: ringato,
    alt: "hungarian-weekend-school-diaspora-organizations-tempus-foundation",
    titleKey: "ringato_title",
    descKey: "ringato_program_desc",
    linkTo: "/edu/kicsiknek#ringato",
  },
  {
    img: kindergarten,
    titleKey: "weekend_kindergarten",
    alt: "israel-hungarian-diaspora-hungary-shcolarships-diaspora-council",
    descKey: "weekend_kindergarten_desc",
    linkTo: "/edu/kicsiknek#kindergarten",
  },
  {
    img: school,
    alt: "hungarian-magyar-hetvegi-iskola-weekend-school-in-jerusalem-diaspora-organizations-nemzetpolitika",
    titleKey: "weekend_school",
    descKey: "weekend_school_desc",
    linkTo: "/edu/kicsiknek#school",
  },
  {
    img: adult2,
    alt: "hungarian-magyar-hetvegi-iskola-weekend-school-in-tel-aviv-diaspora-organizations-bethlen-gabor-alapkezelo",
    titleKey: "adult_courses",
    descKey: "adult_courses_desc",
    linkTo: "/edu/nagyoknak#adult-edu",
  },
];

export const resourceLinks = [
  { href: "https://bgazrt.hu/", text: "Bethlen Gábor Alapkezelő Zrt" },
  {
    href: "https://www.korosiprogram.hu/",
    text: "Körösi Csoma Sándor Program",
  },
  { href: "https://rakocziszovetseg.org/", text: "Rákóczi Szövetség" },
  {
    href: "https://www.kulhonimagyarok.hu/hetvegi-magyar-iskolak/",
    text: "Hétvégi Magyar Iskolák",
  },
  {
    href: "https://www.tpf.hu/english",
    text: "Tempus Foundation",
  },
];
