import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import scholarshipBanner from "../files/SH_logo_sziv.png";
import ScholarshipCard from "../components/ScholarshipCard";
import { stipendiumData } from "../utils/utils";
import filler2 from "../files/stipendium_new_1.jpg";
import filler4 from "../files/stipendium_new2.jpg";
import filler1 from "../files/sh_filler1.jpg";
import filler3 from "../files/sh_filler2.jpg";
import "../styles/ScholarshipPage.css";
import ImageWithSpinner from "../components/ImageWithSpinner";

const StipendiumHungaricumPage = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className='scholarship-page'>
      <Row className='mb-5 my-4'>
        <Col lg={12}>
          <h1 className='text-center'>{t("scholarship_title")}</h1>
        </Col>
      </Row>
      <Row className='my-4 scholarship-banner justify-content-center align-items-center'>
        <Col xs={12} className='text-center'>
          <ImageWithSpinner
            src={scholarshipBanner}
            alt='stipendium-hungaricum-scholarship-study-in-hungary-with-scholarship-free-european-education-university-degree'
            className='img-fluid text-center text-justify'
            style={{}}
          />
          <p className='text-justify'>
            <b>{t("scholarship_desc")}</b>
          </p>
        </Col>
      </Row>

      <Container className='my-5'>
        <Row>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler2}
              className='img-fluid image-frame'
              alt='-hungarian-stipendium-hungaricum-scholarship-free-tuition-education-scholarship-study-in-europe'
            />
          </Col>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler4}
              className='img-fluid image-frame'
              alt='stipendium-hungaricum-scholarship-hungarian-scholarship-scholarship-for-BA-Msc-Phd-medicine-scholarchip-europe'
            />
          </Col>
        </Row>
      </Container>

      <Row>
        {stipendiumData.map((card, index) => (
          <ScholarshipCard key={index} {...card} />
        ))}
      </Row>
      <Row className='my-5'>
        <Col className='text-center'>
          <Button
            variant='primary'
            href='https://stipendiumhungaricum.hu/'
            target='_blank'
          >
            {t("learn_more_apply")}
          </Button>
        </Col>
      </Row>
      <Container className='my-5'>
        <Row>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler1}
              className='img-fluid image-frame'
              alt='stipendium-hungaricum-scholarship-efraim-kishon-hungarian-diaspora-in-israel-study-free-in-hungary-scholarship'
            />
          </Col>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler3}
              className='img-fluid image-frame'
              alt='stipendium-hungaricum-scholarship-israel-study-free-in-europe-scholarship-free-tuition'
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default StipendiumHungaricumPage;
