import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImageWithSpinner from "../components/ImageWithSpinner";
import campImage from "../files/summer-camp-logo.png";
import filler2 from "../files/rakoczi_tabor_1.jpg";
import filler4 from "../files/rakoczi_tabor_2.jpg";
import filler3 from "../files/rakoczi_tabor_3.jpeg";
import filler1 from "../files/rakoczi_tabor_4.jpg";
import "../styles/SummerCampPage.css";

const SummerCampPage = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className='summer-camp-page'>
      <Row className='my-4'>
        <Col lg={12}>
          <h1 className='text-center'>{t("summer_camp_title")}</h1>
        </Col>
      </Row>
      <Row className='mb-4 camp-banner justify-content-center align-items-center'>
        <Col xs={12} className='text-center'>
          <ImageWithSpinner
            src={campImage}
            alt='summer camp for kids'
            className='img-fluid text-center'
            style={{ height: "15em" }}
          />
          <p className='text-justify my-4'>
            <b>{t("summer_camp_desc")}</b>
          </p>
        </Col>
      </Row>
      <Container className='my-5'>
        <Row>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler4}
              className='img-fluid image-frame'
              alt='-hungarian-diaspora-free-tuition-education-scholarship-study-in-europe'
            />
          </Col>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler2}
              className='img-fluid image-frame'
              alt='hungarian-scholarship-scholarship-for-BA-Msc-Phd-medicine-scholarchip-europe'
            />
          </Col>
        </Row>
      </Container>
      <Row className='justify-content-center'>
        <Col md={6} sm={12} style={{ display: "flex" }}>
          <Card className='mb-4 w-100' style={{ flex: 1 }}>
            <Card.Body style={{ display: "flex", flexDirection: "column" }}>
              <Card.Title style={{ textAlign: "center" }}>
                {t("camp_activities_title")}
              </Card.Title>
              <Card.Text>{t("camp_activities_desc")}</Card.Text>
              <Card.Text>{t("tours_and_activities")}</Card.Text>
              {/* If there's a button or another element at the bottom, add this */}
              <div style={{ marginTop: "auto" }}>
                {/* Your button or bottom content here */}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} sm={12} style={{ display: "flex" }}>
          <Card className='mb-4 w-100' style={{ flex: 1 }}>
            <Card.Body style={{ display: "flex", flexDirection: "column" }}>
              <Card.Title style={{ textAlign: "center" }}>
                {t("camp_registration_title")}
              </Card.Title>
              <Card.Text>{t("camp_registration_desc")}</Card.Text>
              <Card.Text>{t("flight_ticket_info")}</Card.Text>
              <div className='contact-info'>
                <a className='phone-link' href='tel:+972542252112'>
                  <i className='fas fa-phone'></i> +972 54 225 2112
                </a>
                <a
                  className='email-link'
                  href='mailto:kishoneducenter@gmail.com'
                >
                  <i className='fas fa-envelope'></i> kishoneducenter@gmail.com
                </a>
              </div>
              <div style={{ marginTop: "auto" }}>
                {/* Your button or bottom content here */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className='my-5'>
        <Col className='text-center'>
          <Button
            variant='primary'
            href={t("learn_more_about_camp")}
            target='_blank'
          >
            {t("learn_more_apply")}
          </Button>
        </Col>
      </Row>
      <Container className='my-5'>
        <Row>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler3}
              className='img-fluid image-frame'
              alt='-hungarian-diaspora-free-tuition-education-scholarship-study-in-europe'
            />
          </Col>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler1}
              className='img-fluid image-frame'
              alt='hungarian-scholarship-scholarship-for-BA-Msc-Phd-medicine-scholarchip-europe'
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default SummerCampPage;
