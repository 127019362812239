import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import LoadingIndicator from "./LoadingIndicator";
import ImageGallery from "./ImageGallery";
import { useTranslation } from "react-i18next";
import useFacebookPhotos from "../hooks/useFacebookPhotos";
const FacebookPhotoBlog = () => {
  const pageId = process.env.REACT_APP_PAGE_ID;
  const token = process.env.REACT_APP_TOKEN;
  const { t } = useTranslation();

  const { photos, isLoading, error, nextPageCursor, fetchPhotos } =
    useFacebookPhotos(pageId, token);

  useEffect(() => {
    fetchPhotos(); // Fetch initial photos on component mount
  }, [fetchPhotos]);

  const handleLoadMore = () => {
    if (nextPageCursor) {
      fetchPhotos(nextPageCursor); // Pass the next page cursor to load more photos
    }
  };

  if (error) {
    return <div>Error loading photos: {error.message}</div>;
  }

  return (
    <Container>
      <h1>Facebook Photo Blog</h1>
      <ImageGallery media={photos} loadMore={handleLoadMore} />

      {isLoading && <LoadingIndicator />}
      {!isLoading && nextPageCursor && (
        <div className='text-center'>
          <Button onClick={handleLoadMore}>{t("load_more")}</Button>
        </div>
      )}
    </Container>
  );
};

export default FacebookPhotoBlog;
