import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import WithLanguageRoutes from "./AppRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import i18n from "./services/i18n";
const App = () => {
  i18n.init();
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Navigate to='/hu/' />} />
        <Route path='/:language/*' element={<WithLanguageRoutes />} />
        <Route path='*' element={<div>404 Page not found</div>} />
      </Routes>
    </Router>
  );
};

export default App;
