import React, { useState } from "react";
import LoadingIndicator from "./LoadingIndicator";
const CustomImage = ({ src, alt, className = "" }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const placeholderImage = "https://via.placeholder.com/150";
  const customUrl = src ? src : placeholderImage;

  return (
    <>
      {isImageLoading && <LoadingIndicator />}
      <img
        src={customUrl}
        alt={alt}
        onLoad={() => {
          setIsImageLoading(false);
        }}
        className={className}
        style={{ display: isImageLoading ? "none" : "block" }}
      />
    </>
  );
};

export default CustomImage;
