import React from "react";
import { Col, Card } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import "../styles/HomePage.css";
import "../styles/ProgramCard.css";
import { useParams } from "react-router-dom";

const ProgramCard = ({ img, title, desc, linkTo }) => {
  const { language } = useParams();
  return (
    <Col sm={6} md={6} lg={3} className='mb-4'>
      <Card className='custom-card responsive-text'>
        <Link to={linkTo} className='custom-card-link'>
          <Card.Img variant='top' src={img} className='card-img-top' />
          <Card.Body>
            <div dir={language === "he" ? "rtl" : "ltr"}>
              <Card.Title>{title}</Card.Title>

              <Card.Text className='responsive-text'>{desc}</Card.Text>
            </div>
          </Card.Body>
        </Link>
      </Card>
    </Col>
  );
};

export default ProgramCard;
