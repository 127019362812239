import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import {
  FcHome,
  FcAbout,
  FcFilmReel,
  FcGraduationCap,
  FcGallery,
  FcInfo,
  FcLike,
  FcCellPhone,
  FcAddressBook,
} from "react-icons/fc";
import "../styles/Header.css";
import logo from "../files/kishon/KISHON EDUCENTER/logo/ksh.jpg";
import { openDonationPopup } from "../utils/utils";
const Header = () => {
  const { t } = useTranslation();
  const { language } = useParams();
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const handleNavbarToggle = () => setIsNavExpanded((prev) => !prev);
  useEffect(() => {
    const updateNavExpanded = () => {
      if (window.innerWidth >= 992) {
        setIsNavExpanded(false);
      }
    };

    window.addEventListener("resize", updateNavExpanded);

    return () => window.removeEventListener("resize", updateNavExpanded);
  }, []);

  return (
    <Navbar
      bg='dark'
      variant='dark'
      expand='lg'
      className='mb-3 p-2'
      onToggle={handleNavbarToggle}
    >
      <Navbar.Brand as={Link} to={`/${language}/`}>
        <img
          src={logo}
          className='d-inline-block align-top responsive-logo'
          alt='magyar-diaszpora-szervezet-izraelben-hetvegi-iskola-weekend-school-hungarian-diaspora-in-israel-hungarian-heritage-and-culture'
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='me-auto'>
          <Nav.Link as={Link} to={`/${language}/`}>
            <FcHome size='1.3em' /> {t("home_page")}
          </Nav.Link>
          <Nav.Link as={Link} to={`/${language}/about-us`}>
            <FcAbout size='1.3em' /> {t("about_us")}
          </Nav.Link>
          <NavDropdown
            title={
              <span>
                {" "}
                <FcGraduationCap size='1.3em' />
                {t("education")}{" "}
              </span>
            }
            id='basic-nav-dropdown'
          >
            <NavDropdown.Item as={Link} to={`/${language}/edu/kicsiknek`}>
              {t("edu_for_kids")}
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to={`/${language}/edu/nagyoknak`}>
              {t("edu_for_adults")}
            </NavDropdown.Item>

            <NavDropdown.Item
              as={Link}
              to={`/${language}/edu/diaspora-hungary-scholarship`}
            >
              {t("diaspora")}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to={`/${language}/edu/stipendium-hungaricum-scholarship-hungary`}
            >
              {t("stipendium")}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to={`/${language}/edu/rakoczi-summer-camp`}
            >
              {t("edu_link_rakoczi")}
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <span>
                {" "}
                <FcFilmReel size='1.3em' />
                {t("culture")}{" "}
              </span>
            }
            id='basic-nav-dropdown'
          >
            <NavDropdown.Item
              as={Link}
              to={`/${language}/culture/current-events`}
            >
              {t("current_events")}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to={`/${language}/culture/past-projects`}
            >
              {t("past_projects")}
            </NavDropdown.Item>
          </NavDropdown>
          {/*<Nav.Link as={Link} to={`/${language}/science`}>
            <FcStatistics size='1.3em' /> {t("science")}
          </Nav.Link>*/}
          <Nav.Link as={Link} to={`/${language}/galleries`}>
            <FcGallery size='1.3em' /> {t("gallery")}
          </Nav.Link>
          <a
            href='#donate'
            onClick={openDonationPopup}
            className='nav-link'
            style={{ cursor: "pointer" }}
          >
            <FcLike size='1.3em' /> {t("donate")}
          </a>
          <a
            className='desktop-hide'
            href='mailto:kishon.educenter@gmail.com'
            style={{
              color: "white",
              textDecoration: "none",
              marginBottom: "3px",
            }}
          >
            <FcAddressBook size='1.3em' />
            kishon.educenter@gmail.com
          </a>
        </Nav>
        <div className='text-light d-flex align-items-center'>
          <Nav.Link
            as={Link}
            to={`/${language}/contact`}
            style={{ marginRight: "10px", display: "none" }}
            className='responsive-hide desktop-hide-alt responsive-hide-menu'
          >
            <FcInfo size='1.3em' /> {t("contact")}
          </Nav.Link>
          <a
            className='responsive-hide desktop-hide-alt responsive-hide-menu'
            href='mailto:kishon.educenter@gmail.com'
            style={{
              color: "inherit",
              textDecoration: "none",
              marginRight: "10px",
            }}
          >
            <FcAddressBook size='1.3em' />
            kishon.educenter@gmail.com
          </a>
          <a
            className='responsive-hide responsive-hide-phone'
            href='tel:+972542252112'
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FcCellPhone size='1.3em' />
            +972 54 225 2112
          </a>
        </div>
      </Navbar.Collapse>
      <LanguageSelector isNavExpanded={isNavExpanded} />
    </Navbar>
  );
};

export default Header;
