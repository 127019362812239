import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../translations/en.json";
import he from "../translations/he.json";
import hu from "../translations/hu.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    he: { translation: he },
    hu: { translation: hu },
  },
  lng: "hu",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
/*import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "http://localhost:5000/api/translations/{{lng}}",
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;*/
