import React from "react";
import styled from "styled-components";
import ReactCountryFlag from "react-country-flag";
import { Dropdown } from "react-bootstrap";

export const FlagWithRef = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <ReactCountryFlag {...props} />
  </div>
));
export const RightAlignedDropdown = styled(Dropdown)`
  .dropdown-menu {
    ${({ isNavExpanded }) => (isNavExpanded ? "left: 0;" : "right: 0;")}
    left: auto;
    top: 100%;
  }
`;

export const StyledReactCountryFlag = styled(FlagWithRef)`
  cursor: pointer;
  font-size: 1.4em;
  margin-left: 0.4em;
  margin-right: 0.2em;
  padding-bottom: 0.2em;
`;

export const StyledDropdown = styled(Dropdown)`
  .dropdown-menu {
    left: 0;
    right: auto;
  }
`;
