import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import { FcDonate, FcAbout, FcGallery, FcBusinessman } from "react-icons/fc";
//import { animated } from "react-spring";
import { useTranslation } from "react-i18next";
//import Carousel from "react-multi-carousel";
import ProgramCard from "../components/ProgramCard";
import "../styles/HomePage.css";
import { HashLink as Link } from "react-router-hash-link";
import ephraim_logo from "../files/ephraim_logo.jpg";
import patreons from "../files/min - Copy (2).jpg";
import ImageWithSpinner from "../components/ImageWithSpinner";
import banner from "../files/efraim-kishon-hungarian-diaspora-org-canvas - Copy (3).png";
import banner2 from "../files/efraim-kishon-weekend-school-tel-aviv.png";
import filler1 from "../files/efraim_kishon1.jpg";
import filler2 from "../files/efraim_kishon2.jpg";
import filler3 from "../files/efraim_kishon3.jpg";
import filler4 from "../files/efraim_kishon4.jpg";
import {
  boldUppercase,
  openDonationPopup,
  programData,
  resourceLinks,
} from "../utils/utils";
import CustomImage from "../components/CustomImage";

const textStyle = {
  border: "1px solid #ccc",
  padding: "10px",
  margin: "10px 0",
  borderRadius: "5px",
  boxShadow: "2px 2px 10px rgba(0,0,0,0.1)",
  backgroundColor: "#f9f9f9",
};

const HomePage = () => {
  const { t } = useTranslation();
  const { language } = useParams();

  return (
    <Container>
      <Carousel fade>
        <Carousel.Item>
          <CustomImage
            src={banner}
            alt='Ephraim Kishon Magyar Oktatasi es Kulturalis Kozpont Hebrew Israeli Language Classes and Diaspora Organization'
            className='d-block w-100'
          />

          <Carousel.Caption>
            <h3 className='text-light'>{t("welcome")}</h3>
            <p>{t("message")}</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <CustomImage
            src={banner2}
            alt='Ephraim Kishon Magyar Oktatasi es Kulturalis Kozpont Hebrew Israeli Language Classes and Diaspora Organization'
            className='d-block w-100'
          />

          <Carousel.Caption>
            <h3 className='text-light'>{t("welcome")}</h3>
            <p>{t("message")}</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <Container className='my-5'>
        <div className='d-flex justify-content-center'>
          <FcAbout size='3em' />
        </div>
        <div style={textStyle}>
          <div className='text-center'>
            <h2>{t("about_us")}</h2>
          </div>
          <div className='d-flex align-items-start'>
            <ImageWithSpinner
              src={ephraim_logo}
              className='img-fluid mr-3'
              alt='efraim-kishon-magyar-diaszpora-szervezet-izraelben-hetvegi-iskola-weekend-school-hungarian-diaspora-in-israel-hungarian-heritage-and-culture'
              style={{ maxWidth: "10%" }}
            />
            <div dir={language === "he" ? "rtl" : "ltr"}>
              <p className='mb-0'>{boldUppercase(t("about_us_homepage"))}</p>
            </div>
          </div>
        </div>
      </Container>
      <Container className='my-5'>
        <Row>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler1}
              className='img-fluid image-frame'
              alt='efraim-kishon-hungarian-diaspora-in-israel-weekend-school'
            />
          </Col>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler3}
              className='img-fluid image-frame'
              alt='efraim-kishon-hungarian-diaspora-in-israel-weekend-school'
            />
          </Col>
        </Row>
      </Container>
      {/* Programs/Services Section */}
      <Container className='my-5'>
        <Row>
          <Col className='text-center'>
            <FcGallery size='3em' />
            <h2>{t("our_programs")}</h2>
          </Col>
        </Row>
        <Row className='d-flex justify-content-center'>
          {programData.map((program, index) => (
            <ProgramCard
              className='d-flex justify-content-center'
              key={index}
              img={program.img}
              title={t(program.titleKey)}
              desc={t(program.descKey)}
              alt={program.alt}
              linkTo={`/${language}${program.linkTo}`}
            />
          ))}
        </Row>
      </Container>
      {/* Latest News/Events */}
      {/* News/Events Cards or List */}
      {/* Testimonials */}
      {/* Call to Action */}
      <Container className='my-5'>
        <Row>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler2}
              className='img-fluid image-frame'
              alt='efraim-kishon-hungarian-diaspora-in-israel-weekend-school'
            />
          </Col>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler4}
              className='img-fluid image-frame'
              alt='efraim-kishon-hungarian-diaspora-in-israel-weekend-school'
            />
          </Col>
        </Row>
      </Container>

      <Container className='my-5'>
        <Row>
          <Col className='text-center'>
            <FcBusinessman className='justify-content-center' size='3em' />
            <h2>{t("patreons")}</h2>
            <ImageWithSpinner
              src={patreons}
              className='img-fluid'
              alt='magyar-diaszpora-szervezet-izraelben-hetvegi-iskola-weekend-school-hungarian-diaspora-in-israel-hungarian-heritage-and-culture-mfa-magyar-kulugyminszterium-bethlene-gabor-alapkezelo'
              style={{ maxWidth: "100%" }}
            />
            <div className='resource-links'>
              {resourceLinks.map((link, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <span>♦</span>}
                  <a
                    href={link.href}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='resource-link'
                  >
                    {link.text}
                  </a>
                </React.Fragment>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className='my-5'>
        <div className='d-flex justify-content-center'>
          <FcDonate size='3em' />
        </div>
        <div style={textStyle}>
          <div className='text-center'>
            <h2>{t("donate_to_us")}</h2>
          </div>

          <div dir={language === "he" ? "rtl" : "ltr"}>
            <p>{t("donate_homepage")}</p>
          </div>
          <div className='d-flex justify-content-center'>
            <Link to={`/${language}/donate`}>
              <Button variant='primary' onClick={openDonationPopup}>
                {t("get_involved")}
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default HomePage;
