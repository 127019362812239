import React from "react";
import { Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ScholarshipCard = ({
  icon,
  title,
  description,
  color = "white",
  textColor = "black",
}) => {
  const { t } = useTranslation();
  const titleStyle = {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: textColor,
  };

  const textStyle = {
    fontSize: "1em",
    lineHeight: "1.5",
    color: textColor,
  };

  return (
    <Col md={4}>
      <Card
        className='text-center mb-4 h-100'
        style={{ backgroundColor: color }}
      >
        <Card.Body>
          {icon}
          <Card.Title style={titleStyle}>{t(title)}</Card.Title>
          <Card.Text style={textStyle}>{t(description)}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ScholarshipCard;
