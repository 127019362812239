import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import placeholder from "../files/150.png";
import logo from "../files/kishon/KISHON EDUCENTER/logo/efrlogxx.png";
import { teamMembers, valuesData } from "../utils/utils";
import filler1 from "../files/efraim_kishon5.jpg";
import filler3 from "../files/efraim_kishon6.jpg";
import { textStyle } from "./../utils/utils";
import ImageWithSpinner from "../components/ImageWithSpinner";

const AboutUsPage = () => {
  const { t } = useTranslation();
  const { language } = useParams();
  return (
    <Container>
      <div dir={language === "he" ? "rtl" : "ltr"}>
        <div style={textStyle}>
          <Row className='justify-content-md-center'>
            <Col md={8} className='text-center'>
              <h1 className='mt-4'>{t("about_us")}</h1>
              <div className='d-flex justify-content-center'>
                <ImageWithSpinner
                  src={logo}
                  alt='magyar-diaszpora-szervezet-izraelben-hetvegi-iskola-weekend-school-hungarian-diaspora-in-israel-hungarian-heritage-and-culture'
                  className='my-3'
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <p>{t("about_us_desc")}</p>
            </Col>
          </Row>
        </div>
        <Container className='my-5'>
          <Row>
            <Col md={6} sm={12}>
              <ImageWithSpinner
                src={filler1}
                className='img-fluid image-frame'
                alt='efraim-kishon-hungarian-diaspora-in-israel-weekend-school'
              />
            </Col>
            <Col md={6} sm={12}>
              <ImageWithSpinner
                src={filler3}
                className='img-fluid image-frame'
                alt='efraim-kishon-hungarian-diaspora-in-israel-weekend-school'
              />
            </Col>
          </Row>
        </Container>
        <Row className='my-5 justify-content-center'>
          {valuesData.map((value, index) => (
            <Col md={6} lg={3} key={index} className='mb-4'>
              <Card className='text-center h-100'>
                <Card.Body>
                  <div className='icon-container mb-3'>{value.icon}</div>
                  <Card.Title>{t(value.title)}</Card.Title>
                  <Card.Text>
                    {t(value.description)}
                    {value.title === "history" && (
                      <Link
                        to={`/${language}/posts/archived/izraelinfo-cikk-article-ephraim-kishon`}
                        style={{
                          display: "inline-block",
                          marginTop: "8px",
                          fontWeight: "500",
                          color: "#0056b3",
                        }}
                        aria-label={t("read_more_about_history")}
                      >
                        <span>{t("read_more_about_history")}</span>
                      </Link>
                    )}
                    {value.title === "partnerships" && (
                      <Link
                        to={`/${language}/posts/archived/diaszpora-szervezet-bemutatasa-tel-aviv`}
                        style={{
                          display: "inline-block",
                          marginTop: "8px",
                          fontWeight: "500",
                          color: "#0056b3",
                        }}
                        aria-label={t("read_more_about_partnerships")}
                      >
                        <span>{t("read_more_about_partnerships")}</span>
                      </Link>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <Row className='my-5 justify-content-center'>
          <Col>
            <h2 className='text-center'>{t("our_team")}</h2>
            <Row className='justify-content-center'>
              {teamMembers(t).map((member, index) => (
                <Col md={4} key={index} className='mb-4'>
                  <Card className='text-center'>
                    <Image
                      src={placeholder}
                      roundedCircle
                      className='mx-auto mt-3'
                      width='100'
                      height='100'
                    />
                    <Card.Body>
                      <Card.Title>
                        {member.name_first} {member.name_second}
                      </Card.Title>
                      <Card.Text>{member.role}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        {/* More content will go here */}
      </div>
    </Container>
  );
};

export default AboutUsPage;
