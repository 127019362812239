import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { FcGraduationCap, FcKindle, FcReading } from "react-icons/fc";

import "../styles/KidsEducationPage.css";
import { useTranslation } from "react-i18next";
import kindergartenImage from "../files/kindergarten5.jpg";
import schoolImage from "../files/school.jpg";
import { boldUppercase } from "./../utils/utils";
import { useParams } from "react-router-dom";
import { FcAddressBook, FcCellPhone } from "react-icons/fc";
import nurseryImage from "../files/kindergarten2.jpg";
const KidsEducationPage = () => {
  const { t } = useTranslation();
  const { language } = useParams();

  const educationCenters = [
    { name: t("center1_name"), location: t("center1_location") },
    { name: t("center2_name"), location: t("center2_location") },
  ];
  const contact = [
    {
      icon: <FcAddressBook size='1.3em' className='mx-1' />,
      contactType: t("email"),
      value: "kishon.educenter@gmail.com",
    },
    {
      icon: <FcCellPhone size='1.3em' className='mx-1' />,
      contactType: t("phone"),
      value: "+972 54 225 2112",
    },
  ];

  return (
    <Container>
      <div dir={language === "he" ? "rtl" : "ltr"}>
        <Row className='my-4'>
          <Col>
            <h1 className='text-center'>
              <FcGraduationCap /> {t("kids_edu_title")}
            </h1>
          </Col>
        </Row>
        <Row>
          {/* Education Details */}
          <Col md={8}>
            <Card className='mb-3'>
              <Card.Body>
                <Card.Title>
                  <FcKindle /> {t("kids_edu_info")}
                </Card.Title>
                <Card.Text>
                  {/* Approach details */}
                  {boldUppercase(t("kids_edu_info_desc"))}
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className='mb-3' id='ringato'>
              <Row className='g-0'>
                <Col md={6}>
                  <Card.Body>
                    <Card.Title>
                      <FcReading /> {t("nursery_edu_learn")}
                    </Card.Title>
                    <Card.Text>
                      {/* Modules details */}
                      {t("nursery_edu_learn_desc")}
                    </Card.Text>
                  </Card.Body>
                </Col>
                <Col md={6}>
                  <Card.Img
                    src={nurseryImage}
                    alt='magyar-diaszpora-szervezet-izraelben-hetvegi-ovoda-weekend-kindergarten-hungarian-diaspora-in-israel-hungarian-heritage-and-culture'
                  />
                </Col>
              </Row>
            </Card>
            <Card className='mb-3' id='kindergarten'>
              <Row className='g-0'>
                <Col md={6}>
                  <Card.Body>
                    <Card.Title>
                      <FcReading /> {t("kindergarten_edu_learn")}
                    </Card.Title>
                    <Card.Text>
                      {/* Modules details */}
                      {t("kindergarten_edu_learn_desc")}
                    </Card.Text>
                  </Card.Body>
                </Col>
                <Col md={6}>
                  <Card.Img
                    src={kindergartenImage}
                    alt='magyar-diaszpora-szervezet-izraelben-hetvegi-ovoda-weekend-kindergarten-hungarian-diaspora-in-israel-hungarian-heritage-and-culture'
                  />
                </Col>
              </Row>
            </Card>
            <Card className='mb-3' id='school'>
              <Row className='g-0'>
                <Col md={6}>
                  <Card.Body>
                    <Card.Title>
                      <FcReading /> {t("school_edu_learn")}
                    </Card.Title>
                    <Card.Text>
                      {/* Modules details */}
                      {t("school_edu_learn_desc")}
                    </Card.Text>
                  </Card.Body>
                </Col>
                <Col md={6}>
                  <Card.Img
                    src={schoolImage}
                    alt='magyar-diaszpora-szervezet-izraelben-hetvegi-iskola-weekend-school-hungarian-diaspora-in-israel-hungarian-heritage-and-culture'
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Centers List */}
          <Col md={4}>
            <h3> {t("kids_edu_where")}</h3>
            <ListGroup>
              {educationCenters.map((center, index) => (
                <ListGroup.Item key={index}>
                  {center.name} - {center.location}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <h3> {t("contact")}</h3>
            <ListGroup>
              {contact.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  className='d-flex align-items-center'
                  action
                  href={
                    item.contactType === t("email")
                      ? `mailto:${item.value}`
                      : `tel:${item.value}`
                  }
                >
                  {item.icon}
                  <span
                    className='ml-2'
                    dir={item.contactType === t("phone") ? "ltr" : undefined}
                  >
                    {item.value}
                  </span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default KidsEducationPage;
