import React from "react";
import styled from "styled-components";
const ResponsiveIframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
`;
const ResponsiveIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
`;
const YoutubeEmbed = ({ url }) => {
  const match = url.match(
    /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/i
  );
  const videoId = match ? match[1] : null;
  if (videoId) {
    return (
      <ResponsiveIframeContainer>
        <ResponsiveIframe
          src={`https://www.youtube.com/embed/${videoId}`}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          title='Embedded YouTube'
        />
      </ResponsiveIframeContainer>
    );
  }
  return (
    <a href={url} target='_blank' rel='noopener noreferrer'>
      {url}
    </a>
  );
};
export default YoutubeEmbed;
