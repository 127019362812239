import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";

import {
  FcManager,
  FcConferenceCall,
  FcCollaboration,
  FcReadingEbook,
} from "react-icons/fc";
import { useTranslation } from "react-i18next";
import "../styles/AdultEducationPage.css";
import learning from "../files/adult.jpg";
import { FcAddressBook, FcCellPhone } from "react-icons/fc";
import { useParams } from "react-router-dom";
const AdultEducationPage = () => {
  const { t } = useTranslation();
  const { language } = useParams();
  const educationCenters = [
    { name: t("center3_name"), location: t("center3_location") },
  ];
  const contact = [
    {
      icon: <FcAddressBook size='1.3em' className='mx-1' />,
      contactType: t("email"),
      value: "kishon.educenter@gmail.com",
    },
    {
      icon: <FcCellPhone size='1.3em' className='mx-1' />,
      contactType: t("phone"),
      value: "+972 54 225 2112",
    },
  ];
  return (
    <Container>
      <div dir={language === "he" ? "rtl" : "ltr"}>
        <Row className='my-4' id='adult-edu'>
          <Col>
            <h1 className='text-center'>
              <FcManager /> {t("adult_edu_title")}
            </h1>
            <p className='text-center'>
              <b>{t("adult_edu_desc")}</b>
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={8}>
            <Card className='mb-3'>
              <Card.Body>
                <Card.Title className='text-center'>
                  <FcConferenceCall /> {t("adult_approach_title")}
                </Card.Title>

                <Card.Text>{t("adult_approach_desc")}</Card.Text>
              </Card.Body>
            </Card>
            <Card className='mb-3'>
              <Row className='g-0'>
                <Col md={6}>
                  <Card.Body>
                    <Card.Title>
                      <FcReadingEbook /> {t("adult_modules_title")}
                    </Card.Title>
                    <Card.Text>
                      {/* Modules details */}
                      {t("adult_modules_desc")}
                      <br />
                      <b>{t("adult_modules_desc2")}</b>
                    </Card.Text>
                  </Card.Body>
                </Col>
                <Col md={6}>
                  <Card.Img
                    src={learning}
                    alt='magyar-diaszpora-szervezet-izraelben-hetvegi-iskola-weekend-school-hungarian-diaspora-in-israel-hungarian-heritage-and-culture'
                  />
                </Col>
              </Row>
            </Card>
            <Card className='mb-3'>
              <Row className='g-0'>
                <Col md={6}>
                  <Card.Body>
                    <Card.Title>
                      <FcCollaboration /> {t("adult_session_title")}
                    </Card.Title>
                    <Card.Text>{t("adult_session_desc")}</Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            <h3>{t("our_centers")}</h3>
            <ListGroup>
              {educationCenters.map((center, index) => (
                <ListGroup.Item key={index}>
                  {center.name} - {center.location}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <h3> {t("contact")}</h3>
            <ListGroup>
              {contact.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  className='d-flex align-items-center'
                  action
                  href={
                    item.contactType === t("email")
                      ? `mailto:${item.value}`
                      : `tel:${item.value}`
                  }
                >
                  {item.icon}
                  <span
                    className='ml-2'
                    dir={item.contactType === t("phone") ? "ltr" : undefined}
                  >
                    {item.value}
                  </span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AdultEducationPage;
